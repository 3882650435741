.highcharts-root {
    text {
        font-weight: 300 !important;
    }
}


.highcharts-credits {
    display: none;
}

