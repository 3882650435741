
//heart Blast Effect

.heart-blast {
    background-position: -1680px 0!important;
    transition: background 1s steps(28);
}

.heart {
    width   	: 60px;
    height  	: 60px;
    display 	: inline-block;
    background  : url('../images/like.png');
    cursor      : pointer;
    margin      : -25px -15px;
}

.search-area{
	width:21.938rem;
	.input-group-text{
		background:#FCFCFC;
		border: 0.0625rem solid #f5f5f5;
		border-left: 0;
		i{
			font-size: 1.4rem;
			font-weight: 600;
			margin-right: 1rem;
		}
		@include respond ('laptop'){
			height:3rem;	
		}	
	}
	.form-control{
		border-radius: 0.75rem;	
		background:#FCFCFC;
		border-right:0;
		border-color:#f5f5f5;
		&:focus{
			border-color:transparent;	
		}
	}
	@include respond ('tab-land'){
		width:12.938rem;	
	}
	@include respond ('tab-port'){
		display:none;	
	}
}
.booking{
	.booking-status{
		span{
			height:4.063rem;	
			width:4.063rem;	
			border-radius:0.5rem;
			background:var(--rgba-primary-1);
			text-align: center;
			line-height: 4.063rem;
		}	
	}
	&:hover{
		box-shadow: 0 1.188rem 1.188rem rgba(0 ,0 ,0  ,0.16)!important;
		.booking-status{
			span{
				background:var(--primary);	
				transition: all .5s ease-in-out;
				svg{
					path{
						fill:$white;	
					}	
				}
			}	
		}
	}	
}	
.event-calender{
	.table-condensed{
		thead{
			tr:first-child{
				position:absolute;
				top:1.2rem;
				right:3rem;
				.picker-switch{
					font-size:1.25rem;
					font-weight:500;
				}
				@include respond('laptop'){
					position:unset;
				}
				@include respond('tab-land'){
					top:1.2rem;
					right:3rem;
				}
				@include respond('phone'){
					position:unset;	
				}
			}	
		}
		tbody{
			tr{
				.day{
					&.active{
						background:var(--secondary)!important;		
					}
					&.today{
						background:var(--primary)!important;			
					}
				}	
			}	
		}
	}	
}
.rooms{
	img{
		width:9.625rem;	
		height:5.75rem;
		border-radius:0.5rem;
		object-fit: cover;
	}
	.users{
		img{
			height:2.5rem;	
			width:2.5rem;
			border-radius:2.5rem;
			margin-right:1rem;
		}	
	}
	.date{
		height:3.313rem;
		width:3.313rem;
		border-radius:0.75rem;
		display: block;
		color: white;
		line-height: 3.313rem;
		text-align: center;
		font-size: 1.2rem;
	}
	.date2{
		height:3.313rem;
		width:7.313rem;
		border-radius:0.75rem;
		display: block;
		color: white;
		line-height: 3.313rem;
		text-align: center;
		font-size: 1.2rem;	
	}
	@include respond('phone'){
		.bed-text{
			h4{
				font-size:1rem;	
			}	
		}	
		img{
			width: 7.625rem;
			height: 4.75rem;	
		}
		.date2{
			height: 2.313rem;
			width: 6.313rem;
			line-height: 2.313rem;
			font-size: 1rem;
		}
		.date{
			height: 2.313rem;
			width: 2.313rem;
			line-height: 2.313rem;
			font-size: 1rem;
		}
	}
}
.coin-tabs{
	.nav-tabs{
		border-bottom:1px solid #D4D4D4;
		.nav-item{
			.nav-link {
				border:0;
				background:transparent;
				font-size: 1rem;
				font-weight: 500;
				padding:0.5rem 1.25rem;
				border-radius: 0;
				cursor: pointer;
				&.active{
					border-bottom:2px solid var(--secondary);
					color:var(--secondary);
					
				}
				&:hover{
					color:var(--secondary);	
				}
			}	
		}
	}
	@include respond('phone'){
		.nav-link{
			padding:0.5rem 0.5rem!important;
		}	
	}
}
.default-progress{
	background:rgba($white,0.2);	
}
.wspace-no{
	white-space:nowrap;	
}
.customers{
	padding: 2rem 2rem;
	margin: 2rem 1rem;
	transition: all .5s ease-in-out;
    border-radius: 1.25rem;
	.customer-profile{
		img{
			height:3.5rem;
			width:3.5rem;
			border-radius:0.5rem;
			
		}	
	}
	.customer-button{
		a{
			font-size:1.5rem;	
			padding:0 0.5rem
		}	
	}
	&:hover{
		box-shadow: 0 1.188rem 1.188rem rgba(0 ,0 ,0  ,0.16);
	}
	@include respond ('phone'){
		padding: 1rem 1rem;	
		margin: 1rem 0.5rem;
	}
}
.front-view-slider .owl-prev, .front-view-slider .owl-next{
	position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    border-radius: 0.75rem;
    top: 50%;
    background:var(--secondary);
    color: $white;
    font-size: 1.125rem;
    margin-top: -1.875rem;
    text-align: center;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;	
	@include respond('phone'){
		width: 2rem;
		height: 2rem;
		line-height: 2rem;	
		font-size: 1rem;
	}
}
.front-view-slider .owl-next{
	right: -3.375rem;
	@include respond('phone-land'){
		right: -2.5rem;	
	}
	@include respond('phone'){
		right: -1.375rem;	
	}
}
.front-view-slider .owl-prev{
	left: -3.375rem;
	@include respond('phone-land'){
		left: -2.5rem;	
	}
	@include respond('phone'){
		left: -1.375rem;	
	}
}
.job-desk3{
	min-width:300px;	
}
.booking-calender{
	.bootstrap-datetimepicker-widget table thead tr:first-child th{
		font-size:1.25rem;
		color:$black;
		font-weight:600;
		padding: 1rem 0.625rem;
	}
	.bootstrap-datetimepicker-widget table td, .patient-calender .bootstrap-datetimepicker-widget table th{
		font-size:1rem;
		color:#161616;
		border-radius:1.375rem;
	}
	.bootstrap-datetimepicker-widget table th{
		font-size:0.875rem;
		font-weight: 500;
		color:rgba(0,0,0,0.6);
		padding-top: 1.5625rem;
		padding-bottom: 1.25rem;
	}
	.bootstrap-datetimepicker-widget .datepicker{
		tbody td{
			&.active{
				background:var(--primary)!important;
				color:$white;
				box-shadow: none;
			}
			&.today{
				box-shadow: none;
				background:var(--primary)!important;
				&:before{
					height: 1.4rem;
					width: 1.4rem;
					border-radius: 1.4rem;
					right: -0.3125rem;
					top: -0.3125rem;
					border: 3px solid #fff;
					background: $warning;
				}
			}
		}
	}
}
.bootstrap-datetimepicker-widget table td.day{
	height: 3.313rem;
    line-height: 0;
    width: 3.313rem;
	border-radius:0.75rem;
}
.dataTables_info{
	margin-left:2rem;	
}
.sidebar-info{
	margin-bottom:1rem;	
}

ol, ul{
	padding-left:0;	
}
.ck.ck-editor__editable_inline>:last-child{
	height:200px;	
}


.image-placeholder {
    position: relative;
    max-width: 130px;
    margin: 10px auto;
    .avatar-edit {
        position: absolute;
        right: -10px;
        z-index: 1;
        top: 0px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f303";
                    font-family: 'Font Awesome 5 Free';
					font-weight: 600;
                    color: #757575;
                    position: absolute;
                    top:6px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .avatar-preview {
        width: 130px;
        height: 95px;
        position: relative;
        border-radius: 30px;
        border: 3px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        & > div {
            width: 100%;
			height: 100%;
            border-radius: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
			overflow: hidden;
			img{
				height: 100%;
				width: 100%;
				object-fit: cover
			}
        }
    }
}
.project-boxed {
    overflow: hidden;
}

.react-calendar__navigation{
	position: absolute;
    top: 0.8rem;
    right: 3rem;
	@include respond('laptop'){
		position:unset;
	}
	@include respond('tab-land'){
		top:1.2rem;
		right:3rem;
	}
	@include respond('phone'){
		position:unset;	
	}
}

.btn-select-drop{
	color: var(--secondary);
    font-size: 1rem;
    font-weight: 600;
	background-color: #fff;
	i{
		font-size: 1.25rem;
		color: #CCCCCC;
	}
}
.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickerDTTabs-tabs,
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover{
    background-color: $primary !important;
}
.MuiPickersClockPointer-thumb{
    border: 14px solid $primary !important;    
}
.MuiButton-textPrimary{
    color: $primary !important;    
}
.MuiInput-underline::after{
    border-bottom: 2px solid $primary !important;    
}